exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-develop-index-jsx": () => import("./../../../src/pages/develop/index.jsx" /* webpackChunkName: "component---src-pages-develop-index-jsx" */),
  "component---src-pages-develop-packages-jsx": () => import("./../../../src/pages/develop/packages.jsx" /* webpackChunkName: "component---src-pages-develop-packages-jsx" */),
  "component---src-pages-develop-web-apps-jsx": () => import("./../../../src/pages/develop/web-apps.jsx" /* webpackChunkName: "component---src-pages-develop-web-apps-jsx" */),
  "component---src-pages-develop-websites-jsx": () => import("./../../../src/pages/develop/websites.jsx" /* webpackChunkName: "component---src-pages-develop-websites-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-store-jsx": () => import("./../../../src/pages/store.jsx" /* webpackChunkName: "component---src-pages-store-jsx" */),
  "component---src-templates-archives-jsx": () => import("./../../../src/templates/archives.jsx" /* webpackChunkName: "component---src-templates-archives-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-design-jsx": () => import("./../../../src/templates/design.jsx" /* webpackChunkName: "component---src-templates-design-jsx" */),
  "component---src-templates-notes-jsx": () => import("./../../../src/templates/notes.jsx" /* webpackChunkName: "component---src-templates-notes-jsx" */)
}

